'use client'

import { Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

const ServiceTitle = ({ isItemOwner, shouldTrimTitle, isSellerBusiness, shouldMinimiseTitle }) => {
  const translate = useTranslate('item.includes_buyer_protection')
  const getTextSize = () => (shouldMinimiseTitle ? Text.Type.Caption : Text.Type.Subtitle)
  const userTypeSuffix = isSellerBusiness ? '_pro' : ''

  const titleTestId = isSellerBusiness
    ? 'service-fee-included-title-pro'
    : 'service-fee-included-title'

  const formatTitle = () => {
    const isTrimmed = shouldTrimTitle || isItemOwner ? '_trimmed' : ''

    return translate(`title_without_price${userTypeSuffix}${isTrimmed}`)
  }

  return (
    <Text
      clickable
      underline={false}
      text={formatTitle()}
      type={getTextSize()}
      testId={titleTestId}
      as="span"
      tabIndex={-1}
    />
  )
}

export default ServiceTitle
