'use client'

import {
  BottomSheet,
  Button,
  Cell,
  Container,
  Divider,
  Dialog,
  Navigation,
  Spacer,
  Text,
} from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import ItemPricingDetailsIcon from 'components/ItemPricingDetailsIcon'
import useBreakpoint from 'hooks/useBreakpoint'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

type Props = {
  itemId: number
  isVisible: boolean
  isSellerBusiness: boolean
  itemPrice: string
  serviceFee: string | null
  onClose: () => void
}

const ItemPriceBreakdownModal = ({
  isVisible,
  itemId,
  itemPrice,
  isSellerBusiness,
  serviceFee,
  onClose,
}: Props) => {
  const translate = useTranslate('item_price_breakdown_modal')
  const breakpoint = useBreakpoint()

  const { track } = useTracking()

  const userTypeSuffix = isSellerBusiness ? '_pro' : ''

  const handleClose = () => {
    track(
      clickEvent({
        screen: Screen.PriceBreakdownModal,
        target: ClickableElement.CloseScreen,
      }),
    )

    onClose()
  }

  const renderActionButton = () => (
    <Cell styling={Cell.Styling.Narrow}>
      <Button
        text={translate('actions.close')}
        styling={Button.Styling.Filled}
        onClick={handleClose}
        testId="item-price-breakdown-close-button"
      />
    </Cell>
  )

  const renderContent = () => (
    <>
      <Navigation
        right={
          <Button
            styling={Button.Styling.Flat}
            iconName={X24}
            inline
            onClick={handleClose}
            aria={{ 'aria-label': translate('actions.a11y.close') }}
            testId="item-price-breakdown-navigation-close-button"
          />
        }
        body={translate('breakdown_title')}
      />
      <Divider />
      <Container styling={Container.Styling.Narrow}>
        <Cell
          title={translate('item_price')}
          suffix={itemPrice}
          styling={Cell.Styling.Narrow}
          testId="item-price-breakdown-price-cell"
        />
        <Cell
          suffix={serviceFee}
          styling={Cell.Styling.Narrow}
          body={
            <div className="u-flexbox">
              <Text
                text={translate(`buyer_protection_fee${userTypeSuffix}`)}
                theme="amplified"
                bold
              />
              <Spacer size={Spacer.Size.Small} orientation={Spacer.Orientation.Vertical} />
              <ItemPricingDetailsIcon itemId={itemId} isSellerBusiness={isSellerBusiness} />
            </div>
          }
          testId="item-price-breakdown-service-fee-cell"
        />
        <Cell styling={Cell.Styling.Narrow}>
          <Text
            text={translate('shipping_info')}
            type={Text.Type.Subtitle}
            testId="item-price-breakdown-shipping-note"
          />
        </Cell>
      </Container>

      <>
        <Divider />
        <Container styling={Container.Styling.Narrow}>
          <Cell styling={Cell.Styling.Narrow}>
            <Text
              testId="legal-note"
              type={Text.Type.Caption}
              text={translate(`legal_note${userTypeSuffix}`)}
            />
          </Cell>
        </Container>
      </>

      <div className="u-tablets-up-only">
        <Container styling={Container.Styling.Narrow}>{renderActionButton()}</Container>
      </div>
    </>
  )

  if (breakpoint.tabletsUp) return <Dialog show={isVisible}>{renderContent()}</Dialog>

  return (
    <BottomSheet isVisible={isVisible} onClose={handleClose}>
      {renderContent()}
    </BottomSheet>
  )
}

export default ItemPriceBreakdownModal
