'use client'

import { useEffect } from 'react'
import { Button, Cell, Icon, Image, Dialog, Navigation, Spacer, Text } from '@vinted/web-ui'
import {
  ExclamationPoint24,
  EyeMagnifyingGlass24,
  SortingCenter24,
  VerifiedCheck24,
  X24,
} from '@vinted/monochrome-icons'

import { ViewableElement } from 'constants/tracking/viewable-elements'
import SeparatedList from 'components/SeparatedList'
import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import { viewEvent } from 'libs/common/event-tracker/events'

type Props = {
  show: boolean
  onClose: () => void
  verificationFee?: string
}

const VERIFICATION_ICON = 'authenticity-diamond-48.svg'
const VERIFICATION_DARK_ICON = 'authenticity-diamond-48-dark.svg'

const OfflineVerificationBuyerModal = ({ verificationFee, show, onClose }: Props) => {
  const translate = useTranslate('item.authenticity_fee_modal')
  const { track } = useTracking()
  const asset = useAsset('/assets/offline-authenticity')

  useEffect(() => {
    if (!show) return

    track(viewEvent({ target: ViewableElement.PhysicalAuthenticityBuyerModal }))
  }, [show, track])

  const contentRows: Array<{
    translationId: string
    iconName?: ComponentProps<typeof Icon>['name']
  }> = [
    { translationId: 'auth_center', iconName: SortingCenter24 },
    { translationId: 'check', iconName: EyeMagnifyingGlass24 },
    { translationId: 'verified', iconName: VerifiedCheck24 },
    { translationId: 'rejected', iconName: ExclamationPoint24 },
    { translationId: 'includes' },
    { translationId: 'delivery' },
  ]

  const verificationIconPath = asset(VERIFICATION_ICON, { theme: { dark: VERIFICATION_DARK_ICON } })

  const renderContentRow = (
    translationId: string,
    iconName: ComponentProps<typeof Icon>['name'] | null = null,
  ) => {
    return (
      <Cell
        key={translationId}
        styling={Cell.Styling.Tight}
        prefix={
          iconName && (
            <Icon
              name={iconName}
              testId={`content-icon-${iconName.Title}`}
              aria={{
                'aria-hidden': 'true',
              }}
              color={Icon.Color.GreyscaleLevel4}
            />
          )
        }
        title={
          <Text
            text={translate(`${translationId}_title`)}
            type={Text.Type.Title}
            testId={`content-title-${translationId}`}
          />
        }
        body={
          <Text
            text={translate(`${translationId}_body`)}
            testId={`content-description-${translationId}`}
          />
        }
      />
    )
  }

  return (
    <Dialog show={show} testId="offline-verification-modal">
      <Navigation
        right={
          <Button
            styling={Button.Styling.Flat}
            onClick={onClose}
            icon={<Icon name={X24} testId="icon-x" />}
            inline
            testId="offline-verification-modal-navigation-close-button"
          />
        }
      />
      <div
        className="modal-contained-content u-ui-padding-horizontal-x2-large"
        data-testid="offline-verification-modal-content"
      >
        <div className="u-text-center">
          <Image src={verificationIconPath} size={Image.Size.XLarge} alt="" />
          <Spacer size={Spacer.Size.Large} />
          <Text text={translate('title')} type={Text.Type.Heading} />
          {verificationFee && (
            <Text
              text={translate('fee', { amount: verificationFee })}
              theme="inherit"
              type={Text.Type.Body}
              testId="offline-verification-modal-fee-title"
              alignment={Text.Alignment.Center}
            />
          )}
        </div>
        <div className="u-ui-padding-vertical-x-large">
          <SeparatedList separator={<Spacer size={Spacer.Size.XLarge} />}>
            {contentRows.map(({ translationId, iconName }) =>
              renderContentRow(translationId, iconName),
            )}
            <Text text={translate('more')} html testId="offline-verification-modal-learn-more" />
            <Text
              text={translate('legal_disclaimer')}
              type={Text.Type.Subtitle}
              testId="offline-verification-modal-legal-disclaimer"
            />
          </SeparatedList>
        </div>
      </div>
      <Cell>
        <Button
          text={translate('action.close')}
          styling={Button.Styling.Filled}
          onClick={onClose}
          testId="offline-verification-modal-close-button"
        />
      </Cell>
    </Dialog>
  )
}

export default OfflineVerificationBuyerModal
